<template>
  <div>
    <!-- #page-header -->
    <div
      id="page-header"
      class="cover"
      data-image-src="assets/img/bg/how-to.jpg"
    >
      <div class="container">
        <div class="-wrapper">
          <div class="_inner">
            <h1 v-translate>Ako to funguje</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-header -->

    <!-- .blocks -->
    <div class="blocks">
      <div class="container">
        <!-- .block -->
        <section class="block -shadow">
          <header>
            <h2 class="alt" v-translate>Vy ako Webpartner</h2>
            <img
              src="assets/img/bg/ako-funguje-webpartner.png"
              v-bind:alt="$gettext('Ako to funguje')"
            />
          </header>

          <div class="text-part">
            <p>
              <strong v-translate
                >Prevádzku technicky zabezpečíme my, ale pre vašich klientov
                nebudeme existovať. Vy budete fakturovať svojim klientom vami
                určené ceny za prevádzku služieb a všetok kontakt so zákazníkmi
                je na vás.</strong
              >
            </p>

            <p>
              <span v-translate
                >V prípade, že budete potrebovať poradiť, je tu pre vás k
                dispozícii náš</span
              >&nbsp;<router-link
                :to="{ name: 'contact' }"
                target="_self"
                v-translate
                >skúsený personál</router-link
              >.
            </p>
          </div>

          <ul class="links">
            <router-link :to="{ name: 'registration' }" tag="li"
              ><a class="bttn -small -button" target="_self" v-translate
                >stať sa partnerom</a
              ></router-link
            >
          </ul>
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block noPadding">
          <!-- ._header -->
          <header class="_header text-center -alt">
            <h2 v-translate>
              Prečo sa oplatí zapojiť do partnerského programu
            </h2>
          </header>
          <!-- /._header -->

          <!-- .icon-boxes -->
          <div class="icon-boxes">
            <div class="row">
              <div class="col-md-4">
                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon -alt">
                    <img
                      src="assets/img/WEBGLOBE_w.svg"
                      v-bind:alt="this.$gettext('Spoľahlivý partner')"
                    />
                  </div>
                  <div class="-content text-left">
                    <h3 class="alt" v-translate>Spoľahlivý partner</h3>

                    <p>
                      <strong v-translate
                        >Tento program zastrešujeme ako jeden z technologicky
                        najvyspelejších poskytovateľov webhostingu na
                        Slovensku.</strong
                      >
                    </p>

                    <p v-translate>
                      Máme výkonné servery, ktoré bežia hladko a všetko sa na
                      nich nastavuje jednoducho pár kliknutiami. Môžete
                      spoľahnúť, že s nami poskytnete svojim klientom to
                      najlepšie.
                    </p>
                  </div>
                </div>
                <!-- /.icon-box -->
              </div>
              <div class="col-md-4">
                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon -alt">
                    <img
                      src="assets/img/icons/ico_webhosting.svg"
                      v-bind:alt="this.$gettext('Domény a hosting')"
                    />
                  </div>
                  <div class="-content text-left">
                    <h3 class="alt" v-translate>Domény a hosting</h3>

                    <p>
                      <strong v-translate
                        >Nakupujte naše služby a produkty za veľkoobchodné ceny
                        a určite si konečnú cenu pre zákazníkov tak, aby ste
                        boli ziskový.</strong
                      >
                    </p>

                    <p v-translate>
                      Čím viac služieb zakúpite, tým lacnejšie budú.
                    </p>
                  </div>
                </div>
                <!-- /.icon-box -->
              </div>
              <div class="col-md-4">
                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon -alt">
                    <img
                      src="assets/img/icons/ico_napredujeme.svg"
                      v-bind:alt="this.$gettext('Neustále napredujeme')"
                    />
                  </div>
                  <div class="-content text-left">
                    <h3 class="alt" v-translate>Neustále napredujeme</h3>

                    <p>
                      <strong v-translate
                        >Sledujeme trendy vývoja technológií u nás aj vo svete,
                        ktoré následne aplikujeme v praxi.
                      </strong>
                    </p>

                    <p v-translate>
                      Naša infraštruktúra je tak pravidelne modernizovaná do
                      stavu zodpovedajúcemu aktuálnym požiadavkám trhu.
                    </p>
                  </div>
                </div>
                <!-- /.icon-box -->
              </div>
            </div>
          </div>
          <!-- /.icon-boxes -->
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block -shadow">
          <!-- ._header -->
          <header class="_header text-center -alt">
            <h2 v-translate>K dispozícii dostanete</h2>
          </header>
          <!-- /._header -->

          <!-- .icons-list -->
          <div class="icons-list">
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_Whitelabel.svg" alt="" />
                  </div>
                  <h3 v-translate>Whitelabelované aplikáce</h3>
                  <p v-translate>
                    Všetky potrebné nástroje môžete obrandovať vlastnou značkou
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_konfigurator.svg" alt="" />
                  </div>
                  <h3 v-translate>Konfigurátor služieb</h3>
                  <p v-translate>
                    Môžete ponúkať predpripravené balíky alebo si navrhnúť úplne
                    nové podľa seba.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_fakturacia.svg" alt="" />
                  </div>
                  <h3 v-translate>Fakturačný modul</h3>
                  <p v-translate>
                    Ak nemáte vlastný fakturačný software, môžete využiť náš.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_api.svg" alt="" />
                  </div>
                  <h3 v-translate>API prístup</h3>
                  <p v-translate>
                    Pomocou API môžete prepojiť náš systém s inými a zjednodušiť
                    si prenos dát medzi nimi.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_livechat.svg" alt="" />
                  </div>
                  <h3 v-translate>LIVE chat</h3>
                  <p v-translate>
                    Budete potrebovať poradiť? Stačí spustiť Live chat a radi
                    vám pomôžeme a vysvetlíme, čo a ako nastaviť.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_zakaznici.svg" alt="" />
                  </div>
                  <h3 v-translate>Zoznam zákazníkov</h3>
                  <p v-translate>
                    Všetkých svojich zákazníkov so službami prehľadne nájdete na
                    jednom mieste.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_cennik.svg" alt="" />
                  </div>
                  <h3 v-translate>Cenník</h3>
                  <p v-translate>
                    Prehľadný cenník s vašimi maloobchodnými cenami a tiež si
                    môžete určovať svoju vlastnú cenu za predaj hostingov a
                    virtuálnych serverov
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_emailing.svg" alt="" />
                  </div>
                  <h3 v-translate>Emailing</h3>
                  <p v-translate>
                    Potrebujete ľudí informovať o novinkách či plánovanej
                    údržbe? Pomocou emailového filtra môžete osloviť konkrétnych
                    zákazníkov podľa ich služby.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_automatizacia.svg" alt="" />
                  </div>
                  <h3 v-translate>Automatizácia</h3>
                  <p v-translate>
                    Žiadne ručné vystavovanie či párovanie faktúr voči nám. Náš
                    systém všetko automaticky urobí, spustí hosting aj ruší
                    nepredĺžené služby.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_webpanel.svg" alt="" />
                  </div>
                  <h3 v-translate>Webpanel</h3>
                  <p v-translate>
                    Vaši klienti si budú môcť cez Webpanel ľahko spravovať svoj
                    hosting, do ktorého budete mať prístup aj vy, keby bolo
                    treba niečo zákazníkom nastaviť.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_platby.svg" alt="" />
                  </div>
                  <h3 v-translate>Spôsoby platby</h3>
                  <p v-translate>
                    Faktúry za naše hostingy môžete platiť kartou Visa, cez
                    Tatrapay, Sporopay, VUB platby, Paypal, Bitcoin a Llitecoin.
                    Tiež si môžete predom dobiť kredit, z ktorého môžete čerpať.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
              <div class="col-md-6 col-lg-3">
                <!-- .icon-list -->
                <div class="icon-list">
                  <div class="icon">
                    <img src="assets/img/icons/ico_jednoduchost.svg" alt="" />
                  </div>
                  <h3 v-translate>Jednoduchosť</h3>
                  <p v-translate>
                    VPrehľadné grafy, objednávky hostingov, domén a zmeny
                    balíkov a parametrov balíkov pár klikmi.
                  </p>
                </div>
                <!-- /.icon-list -->
              </div>
            </div>
          </div>
          <!-- /.icons-list -->
        </section>
        <!-- /.block -->

        <ul class="links">
          <router-link :to="{ name: 'registration' }" tag="li"
            ><a class="bttn -small -button" target="_self" v-translate
              >stať sa partnerom</a
            ></router-link
          >
        </ul>
      </div>
    </div>
    <!-- /.blocks -->

    <section class="section -grey">
      <div class="container">
        <!-- .partners -->
        <div class="partners noOffset">
          <h3 v-translate>
            Na technologickiej infraštruktúre od WY budú vaši klienti v skvelých
            rukách. Našim službám a produktom dôverujú aj:
          </h3>

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/ambitas.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/pravdask.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/radioexpres.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/techbox.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/galton.svg" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.partners -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "how-it-works",
  metaInfo() {
    return {
      title: this.$gettext("how_it_works_title"),
      meta: [
        {
          name: "description",
          content: this.$gettext("how_it_works_meta_description"),
        },
      ],
      htmlAttrs: {
        lang: "sk-SK",
        amp: undefined,
      },
      link: [
        { rel: "canonical", href: "https://webpartner.sk/ako-to-funguje" },
      ],
    };
  },
  methods: {},
};
</script>